import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "rakam";

var rakamService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    rakamTanimlama(rakamAdi, baslik, aciklama, icon, sayi, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            rakamAdi: rakamAdi,
            baslik,
            aciklama,
            icon: icon,
            sayi: sayi,
            durum: durum,
            serviceName: moduleName,
            methodName: "rakamEkle"
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;

    },

    rakamListesi(durum, baslangic, limit) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "rakamListele"
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    rakamSayisi(durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum: durum,
            serviceName: moduleName,
            methodName: "rakamSayisi"
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;

    },


    rakamDurumDegis(rakamID, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            rakamID,
            durum,
            serviceName: moduleName,
            methodName: "rakamAktifPasifEt"
        };

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },


    //$rakamID, $firmaKodu, $rakamAdi, $icon, $sayi, $durum,
    rakamGuncelle(rakamID, rakamAdi, baslik, aciklama, icon, sayi, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            rakamID: rakamID,
            rakamAdi: rakamAdi,
            baslik: baslik,
            aciklama: aciklama,
            icon: icon,
            sayi: sayi,
            durum: durum,
            serviceName: moduleName,
            methodName: "rakamGuncelle"
        };

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    }


}

export default rakamService