<template>
    <div class="page-table mainDiv scrollable only-y" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.rakamlar.islem.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" size="mini" type="text" icon="el-icon-refresh"></el-button>
                </el-tooltip>
            </div>
        </div>
        
        <el-row :gutter="10">
            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="loading || stateLoading"
                :element-loading-text='loading ? $t("src.views.apps.rakamlar.islem.loading") : $t("src.views.apps.rakamlar.islem.stateLoading")'
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 1)">
                <el-row class="mt-0 mb-10">
                    <el-col :lg="16" :md="16" :sm="24" :xs="24">
                        <div>
                            <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray">
                                <el-radio-button label="1">
                                    <i class="mdi mdi-page-next mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                    <transition name="slide-fade">
                                        <label v-if="selectIcon==='1'">{{$t("src.views.apps.rakamlar.islem.activeRakam")}}</label>
                                    </transition>
                                </el-radio-button>

                                <el-radio-button label="0">
                                    <i class="mdi mdi-page-next mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                    <transition name="slide-fade">
                                        <label v-if="selectIcon==='0'">{{$t("src.views.apps.rakamlar.islem.passiveRakam")}}</label>
                                    </transition>
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                    </el-col>
                    <el-col :lg="8" :md="8" :sm="24" :xs="24">
                        <el-col :lg="24" :md="24" :sm="24" :xs="24">
                            <ul class="islemler">
                                <li class="duzenle">
                                    <i class="mdi mdi-pen"></i> {{$t("src.views.apps.rakamlar.islem.duzenle")}}
                                </li>
                                <li v-if="selectIcon === '1'" class="sil">
                                    <i class="mdi mdi-trash-can"></i> {{$t("src.views.apps.rakamlar.islem.sil")}}
                                </li>
                                <li v-else class="aktif">
                                    <i class="mdi mdi-play"></i> {{$t("src.views.apps.rakamlar.islem.yayinaAl")}}
                                </li>
                            </ul>
                        </el-col>
                    </el-col>
                </el-row>
                <el-table border stripe :key="componentKey" :data="rakamlarListe" style="width: 100%">
                    <el-table-column
                        :label='$t("src.views.apps.genel.sira")'
                        type="index"
                        :index="indexMethod"
                        width="80">
                    </el-table-column>
                    <el-table-column
                        :label='$t("src.views.apps.rakamlar.islem.table.id")'
                        prop="rakamID"
                        width="100">
                    </el-table-column>
                    <el-table-column
                        prop="baslik"
                        :label='$t("src.views.apps.rakamlar.islem.table.header")'
                        width="300">
                    </el-table-column>
                    <el-table-column
                        prop="rakamAdi"
                        :label='$t("src.views.apps.rakamlar.islem.table.rakamName")'
                        width="250">
                    </el-table-column>
                    <el-table-column
                        prop="sayi"
                        :label='$t("src.views.apps.rakamlar.islem.table.number")'
                        width="auto">
                    </el-table-column>
                    <el-table-column
                        :label='$t("src.views.apps.rakamlar.islem.table.icon")'
                        prop="icon"
                        width="auto">
                        <template slot-scope="scope" >
                            <el-tooltip :content='scope.row.icon' :open-delay="500" placement="right">
                                <i class="show-icon" :class='scope.row.icon'></i>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label='$t("src.views.apps.genel.islem")'
                        align="right"
                        width="150">
                        <template slot-scope="scope">
                            <el-tooltip :content='$t("src.views.apps.rakamlar.islem.duzenle")' :open-delay="500" placement="bottom">
                                <el-button
                                    v-on:click="selectionIndex=scope.$index; selection=scope.row; updateStart(scope.row); "
                                    type="text"
                                    style="color: #f7ba2a; margin-left: 15px !important;"
                                    icon="mdi mdi-pen mdi-24px">
                                </el-button>
                            </el-tooltip>
                            <el-tooltip :content='$t("src.views.apps.rakamlar.islem.sil")' :open-delay="500" placement="bottom">
                                <el-button
                                    v-if="selectIcon === '1'"
                                    v-on:click="rakamDurumChange(scope.row,scope.$index,'0')"
                                    type="text"
                                    class="buttonDel"
                                    style="margin-left: 15px !important;"
                                    icon="mdi mdi-trash-can mdi-24px">
                                </el-button>
                            </el-tooltip>
                            <el-tooltip :content='$t("src.views.apps.rakamlar.islem.yayinaAl")' :open-delay="500" placement="bottom">
                                <el-button
                                    v-if="selectIcon === '0'"
                                    v-on:click="rakamDurumChange(scope.row,scope.$index,'1')"
                                    type="text"
                                    class="buttonDel ml-15"
                                    icon="mdi mdi-play mdi-24px">
                                </el-button>
                            </el-tooltip>
                        </template>
                     </el-table-column>
                </el-table>
                <el-pagination
                    background
                    layout="sizes,prev, pager, next, jumper, total"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="pageChange"
                    :page-sizes="[10, 20, 50, 100]"
                    :current-page.sync="page"
                    :next-click.sync="page"
                    :prev-click.sync="page"
                    @next-click="pageChange"
                    @prev-click="pageChange">
                </el-pagination>
            </div>
        </el-col>

        <el-col :lg="6" :md="6" :sm="24" :xs="24">
            <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp" v-loading="addLoading || updateLoading"
            :element-loading-text='addLoading ? $t("src.views.apps.rakamlar.islem.addLoading") : $t("src.views.apps.rakamlar.islem.updateLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
                <el-form status-icon :model="rakamForm" :rules="rulesRakam" ref="rakamForm" class="demo-ruleForm">
                    <el-row>
                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                            <el-form-item :label='$t("src.views.apps.rakamlar.islem.table.rakamName")' prop="rakamAdi">:
                                <el-input size="small" v-on:change="$store.commit('changeTabsClose', false)" v-model="rakamForm.rakamAdi" :placeholder='$t("src.views.apps.rakamlar.islem.table.rakamNamePlace")'></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                            <el-form-item :label='$t("src.views.apps.rakamlar.islem.table.header")' prop="baslik">:
                                <el-input size="small" v-model="rakamForm.baslik" :placeholder='$t("src.views.apps.rakamlar.islem.table.headerPlace")' type="textarea"  :autosize="{ minRows: 1, maxRows: 2}"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                            <el-form-item :label='$t("src.views.apps.rakamlar.islem.table.explanation")' prop="aciklama">:
                                <el-input size="small" v-model="rakamForm.rakamAciklama" :placeholder='$t("src.views.apps.rakamlar.islem.table.explanationPlace")' type="textarea"  :autosize="{ minRows: 2, maxRows: 5}"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                            <el-form-item :label='$t("src.views.apps.rakamlar.islem.table.number")' prop="sayi">:
                                <el-input size="small" v-on:change="$store.commit('changeTabsClose', false)" v-model="rakamForm.sayi" v-mask="'######'" :placeholder='$t("src.views.apps.rakamlar.islem.table.numberPlace")'></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                            <el-col :lg="12" :md="12" :sm="12" :xs="12">
                                <el-form-item>
                                    <el-button size="mini" type="success" v-on:click="iconListDialog = true" icon="el-icon-search">{{$t("src.views.apps.rakamlar.islem.selectIcon")}}</el-button>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="12" :md="12" :sm="12" :xs="12">
                                <b>{{rakamForm.icon}}</b>
                            </el-col>
                        </el-col>
                        <el-col :span="24" class="col-p">
                            <el-form-item>
                                <el-button v-if="btnVisible" size="small" v-on:click="rakamTanimla('rakamForm')" type="primary">
                                    {{$t('src.views.apps.rakamlar.islem.ekle')}}
                                </el-button>
                                <el-button v-else size="small" v-on:click="guncelleme('rakamForm')" type="primary">
                                    {{$t('src.views.apps.rakamlar.islem.guncelle')}}
                                </el-button>
                                <el-button v-if="!btnVisible" size="small" v-on:click="vazgec('rakamForm')">
                                    {{$t('src.views.apps.rakamlar.islem.vazgec')}}
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-col>
    </el-row>
        
    <icon :dialogvisible.sync="iconListDialog" v-on:iconSelect="handleIcon"></icon>
    <el-dialog :title='$t("src.views.apps.rakamlar.islem.uyari")' :visible.sync="dialogVisible" width="30%" center>
        <span>"{{selection["rakamAdi"]}}" {{$t('src.views.apps.rakamlar.islem.alertP')}}</span>
        <span slot="footer" class="dialog-footer">
            <el-button icon="el-icon-check" type="success" size="mini" @click="rakamDurumChange(selection,selectionIndex,'0'), dialogVisible = false"></el-button>
            <el-button size="mini" type="danger" icon="el-icon-close" @click="dialogVisible = false"></el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
    import rakamService from '../../../WSProvider/RakamService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'

    import Icon from '@/components/IconSelect.vue'

    export default {
        name: "RakamTanimla",
        components: {
            Icon
        },
        mounted() {
            this.getRakamlarList();
            this.getRakamCount();
        },
        data() {
            return {
                loading: false,
                updateLoading: false,
                stateLoading: false,
                addLoading: false,
                selectIcon: '1',
                radio: '1',
                icon: '',
                rakamlarListe: [],
                updateKodu: '',
                btnVisible: true,
                selection: '',
                selectionIndex: '',
                dialogVisible: false,
                iconListDialog: false,
                componentKey: 0,

                sizes: 10,
                page: 0,
                postPage: 0,
                total: null,

                rakamForm: {
                    rakamAdi: '',
                    sayi: '',
                    icon: '',
                    durum : '',
                    rakamAciklama: '',
                    baslik: '',
                },
                rulesRakam: {
                    rakamAdi: [{
                        required: true,
                        message: "Lütfen Rakam Adı Giriniz.",
                        trigger: 'blur'
                    }],
                    sayi: [{
                        required: true,
                        message: "Lütfen Rakam Giriniz.",
                        trigger: 'blur'
                    }],
                },
            }
        },
        methods: {
            refreshPage(){
                this.page = 1;
                this.postPage = 0;
                this.getRakamlarList();
                this.getRakamCount();
            },

            forceRerender() {
                this.componentKey += 1;
            },

            handleChange(event) {
                this.selectIcon = event;
                this.postPage = 0;
                this.page = 1;
                this.getRakamlarList();
                this.getRakamCount();
                this.forceRerender();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            handleIcon(data) {
                this.rakamForm.icon = data
            },
            
            getRakamlarList() {
               try{
                    this.loading = true;
                    rakamService.rakamListesi(this.selectIcon, this.postPage, this.sizes).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            response.data.forEach(el => {
                                el = functions.removeSlashesAndDecodeStrings(el)
                                // el.rakamAdi = el.rakamAdi ? functions.htmlDecode(el.rakamAdi) : ''
                            })
                            this.rakamlarListe = response.data;
                            this.forceRerender();
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.rakamlarListe = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.loading = false;
                    })
               }catch(e){
                    this.loading = false;
               }
            },

            getRakamCount(){
                try{
                    rakamService.rakamSayisi(this.selectIcon).then((response) => {
                        if (response.status == 200) {
                            this.total = response.data;
                            localStorage.setItem("userDataDemirag", response.token)
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.total = 0
                            }
                        }
                    })
                }catch(e){}
            },

            updateStart(scope) {
                this.updateKodu = scope.rakamID;
                this.rakamForm.rakamAdi = scope.rakamAdi;
                this.rakamForm.rakamAciklama = scope.aciklama
                this.rakamForm.sayi = scope.sayi;
                this.rakamForm.icon = scope.icon;
                this.rakamForm.durum = scope.durum;
                this.rakamForm.baslik = scope.baslik;
                this.btnVisible = false;
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            vazgec(formName) {
                this.$refs[formName].resetFields();
                this.btnVisible = true;
                this.rakamForm.icon = "";
            },

            guncelleme(formName) {
                if(this.validateForm(formName)){
                    this.openConfirmDialog("Rakamı güncellemek istediğinize emin misiniz?").then(() => {
                            try{
                                this.updateLoading = true;
                                rakamService.rakamGuncelle(this.updateKodu, this.rakamForm.rakamAdi, this.rakamForm.baslik, this.rakamForm.rakamAciklama, this.rakamForm.icon ,this.rakamForm.sayi, this.rakamForm.durum).then((response) => {
                                    if (response.status == 200) {
                                        notification.Status('success', this, response.msg);
                                        this.getRakamlarList();
                                        this.getRakamCount();
                                        this.forceRerender();
                                        this.$refs[formName].resetFields();
                                        this.rakamForm.icon = "";
                                        this.rakamForm.rakamAciklama = "";
                                        this.btnVisible = true;
                                    }
                                    this.updateLoading = false;
                                }).catch(err => {
                                    if(err.responseJSON){
                                        let error = err.responseJSON
                                        if(error.status == 401){
                                            EventBus.$emit("userLogout", true)
                                        }
                                        notification.Status("warning", this, error.errMsg)
                                    }else{
                                        //notification.Status(503, this)
                                    }
                                    this.updateLoading = false;
                                })
                            }catch(e){
                                this.updateLoading = false;
                            }
                            this.forceRerender();
                        })
                }
            },

            rakamDurumChange(selection, index ,durum) {
                var msg = durum == '0' ? "Rakamı yayından kaldırmak istediğinize emin misiniz?" : "Rakamı yayına almak istediğinize emin misiniz?"
                this.openConfirmDialog(msg).then(() => {
                    try{
                        this.stateLoading = true;
                        rakamService.rakamDurumDegis(selection.rakamID, durum).then(response => {
                            if (response.status == 200) {
                                localStorage.setItem("userDataDemirag", response.token)
                                this.getRakamlarList();
                                this.getRakamCount();
                                this.stateLoading = false;
                            }
                            notification.Status("success", this, response.msg)
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.stateLoading = false;
                        })
                    }catch(e){
                        this.stateLoading = false;
                    }
                })                
            },

            rakamTanimla(formName) {
                if(this.validateForm(formName)){
                    this.openConfirmDialog("Rakam tanımlamak istediğinize emin misiniz?").then(() => {
                            try{
                                this.addLoading = true;
                                rakamService.rakamTanimlama(this.rakamForm.rakamAdi, this.rakamForm.baslik, this.rakamForm.rakamAciklama, this.rakamForm.icon, this.rakamForm.sayi, 1).then(response => {
                                    if(response.status == 200){
                                        this.getRakamlarList();
                                        this.getRakamCount();
                                        localStorage.setItem("userDataDemirag", response.token)
                                        this.formTemizle(formName)
                                        this.rakamForm.icon = '';
                                    }
                                    this.addLoading = false;
                                    notification.Status("success", this, response.msg)
                                }).catch(err => {
                                    if(err.responseJSON){
                                        let error = err.responseJSON
                                        if(error.status == 401){
                                            notification.Status(150, this);
                                        }
                                        else notification.Status("errorMsg", this, error.errMsg)
                                    }else{
                                        //notification.Status(503, this)
                                    }
                                    this.addLoading = false;
                                })
                            }catch(e){
                                this.addLoading = false;
                            }
                        })
                }
            },

            formTemizle(formName) {
                this.$refs[formName].resetFields();
                this.rakamForm.rakamAciklama = ""
            },

           handleSizeChange(val) {
                this.sizes = val;
                this.getRakamlarList();
                this.getRakamCount();
            },
            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getRakamlarList();
                this.getRakamCount();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            indexMethod(index){
                return ((this.page - 1)* 10) + index + 1;
            },
        }
    }
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';
    .show-icon{
        color: $color-first; 
        font-size: 20px
    }

    .el-pagination {
        margin-top: 20px;
        float: right;
    }
    .el-radio-button--mini .el-radio-button__inner label {
        position: relative;
        bottom: 5px;
    }
    .iconCerceve {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    .aktifIkon {
        background: #77ccbe80;
    }

    .kaldirilmis {
        background: #ec205f;
        color: white;
        font-weight: bold;
        font-size: 16px;
        padding: 10px
    }
</style>
